export enum IntercommunicationMessage {
  Handshake = 'handshake',
  OpenMessenger = 'openMessenger',
  CloseMessenger = 'closeMessenger',
  ExpandMessenger = 'expandMessenger',
  UpdateMessengerSize = 'updateMessengerSize',
  SetMessengerPosition = 'setMessengerPosition',
  SetParentPageContext = 'setParentPageContext',
  Customize = 'customize',
  Authenticate = 'authenticate',
  Logout = 'logout',
  CrossAppEvents = 'crossAppEvents'
}

export enum CrossAppEvents {
  MessengerConfig = 'messengerConfig',
  BoltSearchConfig = 'boltSearchConfig',
  BoltSearchHandoffToMessenger = 'boltSearchHandoffToMessenger',
  PushNotificationAdded = 'pushNotificationAdded',
  PushNotificationRead = 'pushNotificationRead'
}
